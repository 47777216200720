<template>
    <el-dialog title="快捷功能管理" width="80%" top="10px" :close-on-click-modal="false" append-to-body
               :visible.sync="visible">
        <div class="el-descriptions__title">已选择</div>
        <div class="m_b2">
            <el-tooltip effect="dark" :content="item.name" placement="top" v-for="(item, index) in selMenuList"
                        :key="index">
                <div class="menu" @click="delMenu(item, index)">
                    <div :class="['icon', item.icon]"></div>
                    <div class="omit">{{item.name}}</div>
                    <div class="delBtn flex_c_c">
                        <div class="el-icon-delete delIcon"></div>
                    </div>
                </div>
            </el-tooltip>
            <div class="noDataBox" v-if="!selMenuList.length">
                <img src="../../../assets/img/noData.png" class="noData">
                <div>暂未选择快捷功能</div>
            </div>
        </div>
        <div class="el-descriptions__title m_b1">全部</div>
        <div class="flex_l_c">
            <el-input class="m_r1" v-model.trim="menuName" size="small" maxlength="50" placeholder="请输入关键字搜索"
                      clearable style="width: 30%"></el-input>
            <el-button type="primary" @click="getMenu()" size="small" icon="el-icon-search">查询</el-button>
            <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
        </div>
        <div :class="['menuBox', !menuList.length ? 'flex_c_c':'']">
            <el-tooltip effect="dark" :content="item.name" placement="top" v-for="(item, index) in menuList"
                        :key="index">
                <div class="menu" @click="selMenu(item)">
                    <div :class="['icon', item.icon]"></div>
                    <div class="omit">{{item.name}}</div>
                </div>
            </el-tooltip>
            <div class="noDataBox" v-if="!menuList.length">
                <img src="../../../assets/img/noData.png" class="noData">
                <div>暂无相关数据</div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                menuName: '',
                menuIds: [],
                menuList: [],
                selMenuList: [],
            }
        },
        methods: {
            init() {
                this.menuName = ''
                this.menuIds = []
                this.getMenu()
                this.getSelMenuList()
                this.visible = true
            },
            getMenu() {
                this.menuIds = this.selMenuList.map(item => {
                    return item.id
                })
                this.$axios(this.api.user.findByMenuResult, {
                    menuName: this.menuName,
                    menuIds: this.menuIds
                }, 'post').then((res) => {
                    if (res.status) {
                        this.menuList = res.data
                    }
                })
            },
            getSelMenuList() {
                this.$axios(this.api.user.getSysMenuShortcutKeysByCreateBy).then((res) => {
                    if (res.status) {
                        this.selMenuList = res.data
                    }
                })
            },
            selMenu(item) {
                if (this.selMenuList.length >= 9) {
                    this.$message.info('最多选择9个')
                    return
                }
                this.selMenuList.push(item)
                this.getMenu()
            },
            delMenu(item, index) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.selMenuList.splice(index, 1)
                    this.getMenu()
                })
            },
            doSubmit() {
                let arr = []
                this.selMenuList.map((item, index) => {
                    arr.push({
                        menuId: item.id,
                        sort: index,
                    })
                })
                this.$nextTick(() => {
                    this.$axios(this.api.user.sysmenushortcutkeysSave, arr, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg);
                            this.visible = false
                            this.$emit("refreshDataList")
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                })

            },
            reset() {
                this.menuName = ''
                this.getMenu()
            }
        }
    }
</script>

<style scoped lang="scss">
    .menuBox {
        margin-top: 15px;
        height: 280px;
        overflow-y: auto;
    }

    .menu {
        padding: 10px 10px 0px;
        height: 70px;
        vertical-align: bottom;
        margin-top: 15px;
        text-align: center;
        border-radius: 0.29rem;
        border: 1px solid #EEEEEE;
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 10%;
        margin-right: 1%;
    }

    .menu:hover {
        border: 1px solid #AE3F3F;

        .delBtn {
            background: rgba(253, 243, 243, 0.5);
        }

        .delIcon {
            display: block;
        }
    }

    .delBtn {
        border-radius: 0.29rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        color: #AE3F3F;
        font-size: 22px;
    }

    .delIcon {
        display: none;
    }

    .icon {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .noDataBox {
        text-align: center;
        font-size: 12px;
        color: #333333;
    }

    .noData {
        width: 7rem;
    }

    .el-descriptions__title {
        color: #000;
    }
</style>
